<!--申请应标资格 -->
<template>
  <!--  -->
  <div>
    <back />
    <div class="bidEligibility" v-loading="loading">
      <!-- 基本信息 -->
      <div class="detail">
        <header>应标资格申请</header>
        <!-- 平台标资格+独立标资格-->
        <div class="form2">
          <el-form label-width="120px" :model="basicInfo">
            <el-row>
              <el-col :span="basicInfo.paymentStatus == 1 ? 8 : 11">
                <el-form-item label="应标金额">
                  <div style="display: flex;">
                    <el-input v-model="basicInfo.payMoney" readonly
                      v-if="this.$route.query.biddingStatus == 2"></el-input>
                    <div style="display: flex;" v-else>
                      <el-input v-model="basicInfo.eligiblePrice" readonly></el-input>
                      <el-button style="margin-left: 5px;" type="primary" v-show="this.$route.query.biddingStatus == 1"
                        @click="handleClick">选择</el-button>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="basicInfo.biddingStatus == 1 ? 8 : 11">
                <el-form-item label="应标资格类型">
                  <div class="eligiblePrice">{{ this.$route.query.biddingStatus == 1 ? '平台资格' : '独立标资格' }}</div>
                </el-form-item>
              </el-col>
              <el-col :span="basicInfo.biddingStatus == 1 ? 8 : 11" v-if="this.$route.query.biddingStatus == 2">
                <el-form-item label="标号">
                  <el-input v-model="basicInfo.tendersLabel" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="basicInfo.biddingStatus == 1 ? 8 : 11" v-if="this.$route.query.biddingStatus == 2">
                <el-form-item label="标书名称">
                  <el-input v-model="basicInfo.tendersName" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="basicInfo.paymentStatus == 1">
                <el-form-item label="缴费状态">
                  <div class="paymentStatus">未缴费</div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="11" v-if="this.$route.query.biddingStatus == 1">
                <el-form-item label="应标资格有效期">
                  <el-input v-model="basicInfo.validDate" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="可投标商品次数" v-if="this.$route.query.biddingStatus == 1">
                  <el-input v-model="basicInfo.myTendersCount" readonly></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-form label-width="120px" :model="rulesInfo" :rules="rules" ref="ruleForm">
            <el-row>
              <el-col :span="11">
                <el-form-item label="配送区域" prop="selectedOptions">
                  <el-cascader ref="cascader" size="large" placeholder="请输入配送区域" style="width: 60%;" :options="options"
                    :props="{ label: 'name', value: 'name', expandTrigger: 'hover', multiple: true, }"
                    v-model="rulesInfo.selectedOptions" @change="handleChange" collapse-tags clearable>
                  </el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="货车数量" prop="truckNumber">
                  <el-input placeholder="请输入货车数量" v-model="rulesInfo.truckNumber"></el-input>
                </el-form-item>
              </el-col>

            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="合作院校" prop="partnerInstitutions">
                  <el-input placeholder="请输入合作院校" v-model="rulesInfo.partnerInstitutions"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="Btn">
        <el-button type="primary" v-if="basicInfo.paymentStatus == 1" @click="handlePay">去缴费</el-button>
        <el-button type="primary" v-else @click="submitForm('ruleForm')">下一步</el-button>
      </div>
      <!-- 平台资格选择弹窗 -->
      <el-dialog title="应标资格费用" :visible.sync="dialogVisible" @close="handleCloseDialog">
        <div class="globle_table tableBox">
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
            :row-class-name="tableRowClassName" @row-click="onRowClick">
            <el-table-column label="单选">
              <template slot-scope="scope">
                <el-radio :label="scope.$index" v-model="radio"></el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="levelName" label="应标级别名称" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="eligiblePrice" label="金额">
            </el-table-column>
            <el-table-column prop="tendersCount" label="可投标商品次数（次）">
              <template slot-scope="scope">
                <!-- 可投标商品次数（-1为无限次） -->
                <div>{{ scope.row.myTendersCount == -1 ? '不限' : scope.row.myTendersCount }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="validDate" label="资格有效期（天）">
            </el-table-column>
          </el-table>

        </div>
        <!-- 分页 -->
        <div slot="footer">
          <div class="pagebox">
            <el-pagination background @current-change="handleCurrentChange" :current-page.sync="currentPage"
              :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total"
              :total="totalCount">
            </el-pagination>
          </div>
          <span class="dialog-footer">
            <el-button type="primary" size="small" plain @click="handleCloseDialog">取 消</el-button>
            <el-button type="primary" size="small" @click='handleVerify'>确定</el-button>
          </span>
        </div>
      </el-dialog>
      <!-- 手机验证码 -->
      <child :showFlag="showFlag" @closeChildDialog="closeChildDialog" v-if="flag"></child>
    </div>

  </div>
</template>

<script>
import child from './../detail/paymentDet.vue'
// import { pcaTextArr } from 'element-china-area-data'//全国地址插件

export default {
  data() {
    return {
      loading: false,
      showFlag: false,//缴费组件显隐
      flag: false,
      basicInfo: {},//基本信息
      rulesInfo: {
        truckNumber: '',//货车数量
        partnerInstitutions: '',//合作院校
        selectedOptions: null,//配送区域
      },//输入信息
      options: require('../../../assets/js/pca-code.json'), // 省市区数据(三维数组),
      deliveryAreaDtoList: null,//提交的配送地址
      rules: {
        truckNumber: [
          { required: true, message: '请输入货车数量', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              // 正则表达式校验：如果值不为空，且不是纯数字，则返回错误提示  
              if (value && !/^\d+$/.test(value)) {
                callback(new Error('只能输入数字'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }
        ],
        partnerInstitutions: [
          { required: true, message: '请输入合作院校', trigger: 'blur' },
        ],
        selectedOptions: [
          { required: true, message: '请输入配送区域', trigger: 'blur' },
        ],
      },
      // 弹窗
      dialogVisible: false,
      radio: null,//弹窗数据单选
      tableData: [],
      // 分页器
      currentPage: 1, // 当前页码
      pageSize: 5, // 每页显示的行数
      pageSizeArr: [5, 10, 20, 50],
      totalCount: 0, // 总记录数据
    }
  },
  components: {
    child,
  },
  created() {
    // console.log(pcaTextArr);
    if (!this.dialogVisible && this.$route.query.biddingStatus == 2) {
      this.getRequest2(this.$route.query.tendersId)
    }
  },
  methods: {
    // 平台标金额列表请求
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.sendSelectTendersEligible, {
        params: {
          status: 1,//状态(1:有效；2：失效)
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.totalCount = result.totalCount
          result.list.forEach(element => {
            element.radioValue = false
          });
          this.tableData = result.list.map(e => {
            return {
              ...e,
              myTendersCount: e.tendersCount == -1 ? '不限' : e.tendersCount
            }
          })
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })

    },
    // 独立标详情
    getRequest2(tendersId) {
      this.loading = true;
      const url = `${this.$api.sendTendersNoticeCheck}/${tendersId}`
      this.$axios.get(url).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.basicInfo = result
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })

    },
    // 区域下拉选择
    handleChange(value) {
      // console.log('区域下拉选择', value);
      let objects = value.map(subArray => ({
        province: subArray[0],
        city: subArray[1],
        district: subArray[2]
      }));
      this.deliveryAreaDtoList = objects
    },
    // 校验信息
    submitForm(formName) {
      this.loading = true;
      this.flag = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post(this.$api.sendAddEligible, {
            eligibleId: this.$route.query.biddingStatus == 2 ? this.basicInfo.tendersId : this.basicInfo.id,//资格类型=1为投标资格表id，=2为招标id
            eligiblePrice: this.$route.query.biddingStatus == 2 ? this.basicInfo.payMoney : this.basicInfo.eligiblePrice,//资格价格
            eligibleType: this.$route.query.biddingStatus,//资格类型
            validDate: this.basicInfo.validDate,//有效期
            tendersCount: this.basicInfo.tendersCount || null,//次数
            cooperateCollege: this.rulesInfo.partnerInstitutions,//合作院校
            // deliveryArea: this.rulesInfo.areaDistribution,//配送区域
            deliveryAreaDtoList: this.deliveryAreaDtoList,//配送区域
            truckNumber: this.rulesInfo.truckNumber,//货车数量
          }).then((res) => {
            this.loading = false;
            const { code, result } = res.data
            if (code == 100) {
              let query = {
                sourceId: result, // 对应平台的主键id
                fee: this.$route.query.biddingStatus == 2 ? this.basicInfo.payMoney : this.basicInfo.eligiblePrice, // 缴费金额
                supplierId: this.$store.state.userInfo.supplierId, //供应商id
                sourceType: JSON.parse(localStorage.getItem('bidList')).eligibleType, // 支付来源：1平台应标资格；2独立标应标资格；3保证金
              }
              this.$store.commit('setPayQuery', query)
              this.handlePay()
            }
          }).catch((err) => {
            console.log(err);
          })
        } else {
          this.$message.error('请输入完整信息！');
          return false;
        }
      });
    },
    // 检查用户当前支付平台绑定状态
    handlePay() {
      this.$axios.post(this.$api.select_token, {
        phoneNum: this.$store.state.userInfo.userAccount, // 手机号
        supplierId: this.$store.state.userInfo.supplierId, // 供应商id
      }).then((res) => {
        if (res.data.code == 100) {
          // result为空时该供应商没有绑定第三方支付平台
          if (res.data.result == null) {
            this.flag = true
            this.showFlag = true
          } else {
            this.$router.replace({ name: 'paymentPage' })
          }
        }
      });
    },
    // 检验重置
    resetForm() {
      this.ruleForm = {};//清空对象
      this.$refs.ruleForm.resetFields();
    },
    //  接受子组件调用的关闭弹出框方法
    closeChildDialog() {
      //点击取消按钮直接跳转到投标列表
      this.showFlag = false
      this.$router.push({ name: 'bidList', })
    },
    // 平台资格选择
    handleClick() {
      this.getRequest()
      this.dialogVisible = true
    },

    // 确认关闭
    handleVerify() {
      this.dialogVisible = false
    },
    // 关闭
    handleCloseDialog() {
      this.dialogVisible = false
    },
    // 分页
    onCurrentPage() {
      this.currentPage = 1
      this.getRequest()
    },
    // 分页器
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.onCurrentPage()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getRequest()
    },
    // 选择表格单选处理
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    // 选择表格单选数据选择
    onRowClick(row) {
      this.radio = row.index
      // console.log('表格单选', row);
      this.basicInfo = row
    },

  },
}
</script>

<style scoped lang='scss'>
.bidEligibility {
  padding: 0 30px;

  .detail {
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 20px;


    header {
      height: 45px;
      border-radius: 6px 6px 0px 0px;
      ;
      padding-left: 30px;
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 16px;
      color: #1A1A1A;
      line-height: 45px;
    }

    .form {
      padding: 20px 20px 0px 10px;
    }

    .form2 {
      padding: 20px 20px 0px 10px;
    }

    .eligiblePrice {
      color: #606266;
      padding: 0px 10px 0px 10px;
      background: #f2f2f2;
      border-radius: 10px;
    }

  }

  .Btn {
    text-align: center;
  }

  /deep/ .el-radio__label {
    display: none;
  }

  .pagebox {

    /deep/ .el-input,
    /deep/ .el-input__inner {
      width: 90px;
    }

  }


}
</style>